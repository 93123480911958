<template>
  <div>
    <Years @year="changeYear($event)" > 
      <!-- <router-link to="/dashboard/addExam" class="px-4 py-2 text-white font-semibold bg-red-400 rounded">Ajouter un sujet</router-link> -->
    </Years>
  </div>
</template>

<script>
import Years from '../dashboard/Years.vue'
import AddExam from '../dashboard/AddExam.vue'
export default {
  components:{
    Years
  },

  data () {
     return {
        currentRoute: window.location.pathname
     }
   },

  props:{
    linkPath:Function,
  },

  mounted () {
    this.linkPath(this.currentRoute)
  },

  methods:{
    changeYear(year){
      this.$router.push("/dashboard/addExam/?year="+year);
    }
  }
}
</script>

<style>

</style>